import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, TreeSelect } from "antd";
import mainLogo from "../mainLogo.png";
import NameLogo from "../NAME.png";
import URL from "../config";
import axios from "axios";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import "animate.css";

const SpecialClientPage = () => {
  const [form] = Form.useForm();
  const [treeData, setTreeData] = useState([]);
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);
  const [selectedChoix, setSelectedChoix] = useState([]);
  const [selectedMontant, setSelectedMontant] = useState(0);
  const [credit, setCredit] = useState();
  const { nom } = useParams();

  const handleFormSubmit = async () => {
    try {
      const date = moment().format("YYYY/MM/DD");
      const consoData = {
        nom: nom,
        choix: selectedChoix,
        montant: selectedMontant,
        date: date,
      };
      await axios.post(`${URL}/Conso/addNewSpecialConso`, consoData);
      toast.success("Votre achat a été bien effectué", {
        position: toast.POSITION.TOP_CENTER,
      });
      form.resetFields();
      setSelectedMontant(0);

      axios
        .get(`${URL}/Users/getUserByNom/${nom}`)
        .then((res) => setCredit(res.data[0].credit))
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onChange = (selectedValues) => {
    const selectedItems = treeData
      .flatMap((category) => category.children || [])
      .filter((item) => selectedValues.includes(item.titleSansRemise));

    const menuChoices = selectedItems
      .map((item) => item.titleSansRemise)
      .join(" / ");

    const totalMontant = selectedItems
      .map((item) => item.montant)
      .reduce((a, b) => a + b, 0);

    setSelectedChoix(menuChoices);
    setSelectedMontant(totalMontant);
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  useEffect(() => {
    axios
      .get(`${URL}/Menu/getMenuALL`)
      .then((response) => {
        const menuData = response.data;
        const categories = [...new Set(menuData.map((item) => item.category))];
        const formattedTreeData = categories.map((category) => {
          const categoryItems = menuData
            .filter((item) => item.category === category)
            .map((item) => ({
              key: item.choix,
              value: item.choix,
              title: `${item.choix}  ${item.montant.toFixed(2)} DT`,
              titleSansRemise: item.choix,
              remise: item.montantRemise,
              montant: item.montant,
            }));
          return {
            key: category,
            value: category,
            title: category,
            children: categoryItems,
          };
        });
        setTreeData(formattedTreeData);
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
      });

    axios
      .get(`${URL}/Users/getUserByNom/${nom}`)
      .then((res) => setCredit(res.data[0].credit))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="App-header">
      <ToastContainer />
      <img src={mainLogo} className="App-logo" alt="logo" />
      <img
        src={NameLogo}
        alt="name logo"
        className="Name-logo animate__animated animate__flip"
      />
      <Form
        layout="horizontal"
        form={form}
        style={{ maxWidth: 800, width: "100%" }}
        className="form"
      >
        <Form.Item
          name="commande"
          label="Menu"
          rules={[
            { required: true, message: "veuillez choisir votre commande" },
          ]}
        >
          <TreeSelect
            showSearch
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 600,
              overflow: "auto",
            }}
            placeholder="Choisissez Votre Commande"
            allowClear
            multiple
            treeLine={true}
            treeExpandAction="click"
            treeDefaultExpandAll={false}
            onChange={(e) => onChange(e)}
            treeData={treeData}
          />
        </Form.Item>
        <div className="priceContainer animate__animated animate__backInLeft">
          <p className="montantText ">
            Montant total: <span className="total">{selectedMontant}DT</span>
          </p>
        </div>

        <Form.Item
          style={{ marginTop: "4rem" }}
          className="animate__animated animate__bounceInUp"
        >
          <p
            className="montantText"
            style={{ textAlign: "center", fontSize: "22px" }}
          >
            Votre Crédit est :{" "}
            <span
              style={{ fontSize: "24px", color: "#b1987e", fontWeight: "bold" }}
            >
              {credit} DT
            </span>
          </p>
          <Button
            type="primary"
            style={{ width: "100%", color: "white" }}
            onClick={handleFormSubmit}
            disabled={!submittable}
          >
            Envoyer
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SpecialClientPage;
