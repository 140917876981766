// StaticMenu.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "../../config";
import mainLogo from "../../mainLogo.png";
import { Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/virtual";

const StaticMenu = () => {
  const [treeData, setTreeData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  const handleCategoryClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    axios
      .get(`${URL}/Menu/getMenuALL`)
      .then((response) => {
        const menuData = response.data;
        const categories = [...new Set(menuData.map((item) => item.category))];
        const formattedTreeData = categories.map((category) => {
          const categoryItems = menuData
            .filter((item) => item.category === category)
            .map((item) => ({
              key: item.choix,
              value: item.choix,
              title: item.choix,
              remise: item.montantRemise,
              montant: item.montant,
            }));
          return {
            key: category,
            value: category,
            title: category,
            children: categoryItems,
          };
        });
        setTreeData(formattedTreeData);
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
      });
  }, []);

  return (
    <div className="App-header" style={{ backgroundColor: "white" }}>
      <img src={mainLogo} className="App-logo" alt="logo" />
      <Swiper
        // modules={[Virtual]}
        slidesPerView={3}
        style={{ width: "100%" }}
        onSlideChange={handleSlideChange}
        centeredSlides={true}
      >
        {treeData.map((category, index) => (
          <SwiperSlide
            key={index}
            virtualIndex={index}
            onClick={() => handleCategoryClick(index)}
          >
            <p
              style={{
                fontSize: "13px",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
              className={`${activeIndex === index ? "active_category" : ""}`}
            >
              {category.key}
            </p>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="articleGridContainer">
        {treeData[activeIndex]?.children.map((child, childIndex) => (
          <div key={childIndex} className="articleContainer">
            <div className="childTitle" style={{ color: "black" }}>
              {child.key}
            </div>
            <div style={{ color: "black", fontSize: 20, fontWeight: 700 }}>
              {child.montant} DT
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaticMenu;
