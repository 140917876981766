import { useState, useEffect } from "react";
import {
  Flex,
  Input,
  Table,
  DatePicker,
  Button,
  Form,
  Select,
  Tabs,
} from "antd";
import React from "react";
import axios from "axios";

import URL from "../../config";
import ExportButton from "../ExportButton";

const { RangePicker } = DatePicker;

const UsersTable = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [fetchedColumns, setFetchedColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [totalMoney, setTotalMoney] = useState();
  const [users, setUsers] = useState([]);
  const [selectedMatricule, setSelectedMatricule] = useState(null);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleReset = () => {
    form.resetFields();
    setSearchTerm("");
    setSelectedDateRange([]);
    setFetchedData([]);
    setTotalMoney("");
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const filteredFetchedData = fetchedData.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const onFinish = async (values) => {
    try {
      const { matricule } = values;

      if (matricule) {
        setSelectedMatricule(matricule);
        const requestData = { matricule };

        if (selectedDateRange && selectedDateRange.length === 2) {
          const startDate = selectedDateRange[0].format("YYYY-MM-DD");
          const endDate = selectedDateRange[1].format("YYYY-MM-DD");

          requestData.startDate = startDate;
          requestData.endDate = endDate;
        }

        const response = await axios.post(
          `${URL}/Rapport/SearchConsoCalcul`,
          requestData
        );

        // Update fetchedData and columns based on the keys in fetchedData
        setFetchedData(response.data.results);
        setTotalMoney(response.data.totalMontant);
        const dynamicColumns = Object.keys(response.data.results[0]).map(
          (key) => ({
            title: key.charAt(0).toUpperCase() + key.slice(1),
            dataIndex: key,
            key: key,
          })
        );
        setFetchedColumns(dynamicColumns);
      } else {
        console.error("Matricule is required");
      }
    } catch (error) {
      console.error("Error in onFinish:", error);
    }
  };

  const dataSource =
    fetchedData.length > 0 ? filteredFetchedData : filteredData;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}/Rapport/getAllConso`);
        setData(response.data);

        const dynamicColumns = Object.keys(response.data[0]).map((key) => ({
          title: key.charAt(0).toUpperCase() + key.slice(1),
          dataIndex: key,
          key: key,
        }));

        setColumns(dynamicColumns);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/Users/getAllUsers`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <>
      <Flex
        justify="space-between"
        style={{ width: "100%", marginBottom: "2rem" }}
      >
        <div>
          <Form
            form={form}
            style={{ display: "flex", gap: "15px" }}
            onFinish={onFinish}
          >
            <RangePicker
              value={selectedDateRange}
              onChange={(value) => setSelectedDateRange(value)}
              style={{ width: "220px", height: "32px" }}
            />
            <Form.Item name="matricule">
              <Select
                showSearch
                placeholder="Rechercher un collaborateur"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={users.map((user) => ({
                  value: user.matricule,
                  label: user.matricule,
                }))}
              />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Recherche
            </Button>
            <Button type="primary" danger onClick={handleReset}>
              Reset
            </Button>
            <ExportButton
              dates={selectedDateRange}
              matricule={selectedMatricule}
            />
          </Form>
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          {fetchedData.length === 0 ? null : (
            <>
              {totalMoney && (
                <span style={{ fontSize: "25px" }}>
                  Montant Total:
                  <strong style={{ color: "#b1987e" }}> {totalMoney}DT</strong>
                </span>
              )}
            </>
          )}
        </div>
        <Input
          placeholder="Recherche"
          style={{ width: "200px", height: "32px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Flex>
      <Table
        style={{ width: "100%" }}
        columns={fetchedColumns.length > 0 ? fetchedColumns : columns}
        dataSource={dataSource}
        loading={loading}
        locale={{
          emptyText: "Il n'y a aucune information à présenter",
        }}
      />
    </>
  );
};

export default UsersTable;
