import { Table } from "antd";
import React, { useEffect, useState } from "react";
import URL from "../../config";
import axios from "axios";

const SpecialUsers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}/Rapport/getSpecialConso`);
        setData(response.data);

        const dynamicColumns = Object.keys(response.data[0]).map((key) => ({
          title: key.charAt(0).toUpperCase() + key.slice(1),
          dataIndex: key,
          key: key,
        }));

        setColumns(dynamicColumns);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={data}
        loading={loading}
        locale={{
          emptyText: "Il n'y a aucune information à présenter",
        }}
      />
    </>
  );
};

export default SpecialUsers;
