import { useState, useEffect } from "react";
import { Flex, Input, Table, Button, Form, message } from "antd";
import React from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import URL from "../config";

//const { RangePicker } = DatePicker;

const AllUsers = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAddUser = async () => {
    try {
      const values = await form.validateFields();
      const formData = {
        matricule: values.matricule,
        nom: values.nom,
      };

      console.log(formData);

      await axios.post(`${URL}/Users/addUser`, formData);
      toast.success("Utilisateur Ajouté avec succès", {
        position: toast.POSITION.TOP_CENTER,
      });
      form.resetFields();
    } catch (error) {
      console.error("Error adding user:", error);
      message.error("Matricule déja utilisé !");
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleExportImage = (record) => {
    const qrCodeDataUrl = record.qr_code; // Replace with the actual key for the QR code image

    // Create a temporary image element
    const image = document.createElement("img");

    // Set the image's src attribute to the QR code data URL
    image.src = qrCodeDataUrl;

    // Create a temporary anchor element
    const anchor = document.createElement("a");

    // Set the anchor's href attribute to the QR code data URL
    anchor.href = qrCodeDataUrl;

    // Set the anchor's download attribute with a suggested filename
    anchor.download = `QRCode_${record.matricule}_${record.nom}.png`;

    // Append the anchor to the document
    document.body.appendChild(anchor);

    // Append the image to the anchor
    anchor.appendChild(image);

    // Simulate a click on the anchor to trigger the download
    anchor.click();

    // Remove the anchor and image from the document
    document.body.removeChild(anchor);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}/Users/getAllUsers`);
        setData(response.data);

        // Dynamically generate columns based on the keys in the first data object
        const dynamicColumns = Object.keys(response.data[0]).map((key) => ({
          title: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
          dataIndex: key,
          key: key,
          render: (text, record) =>
            key === "qr_code" ? (
              <img
                src={text} // Assuming text is the URL of the image
                alt={`QR Code for ${record.matricule}`}
                style={{ maxWidth: "100%", maxHeight: "80px" }}
              />
            ) : (
              text
            ),
        }));

        // Add a new column for the delete button
        dynamicColumns.push({
          title: "Supprimer Collaborateur",
          dataIndex: "delete",
          key: "delete",
          render: (_, record) => (
            <Button
              type="primary"
              ghost
              danger
              size={"large"}
              onClick={() => handleDeleteUser(record.matricule)}
            >
              Supprimer
            </Button>
          ),
        });

        const handleDeleteUser = async (matricule) => {
          try {
            await axios.delete(`${URL}/Users/DeleteUsers`, {
              data: { matricule }, // Send the matricule in the request body
              headers: {
                "Content-Type": "application/json",
              },
            });
            toast.success("Utilisateur supprimé avec succès", {
              position: toast.POSITION.TOP_CENTER,
            });

            // Refetch the data after deletion to update the table
            fetchData();
          } catch (error) {
            console.error("Error deleting user:", error);
            message.error("An error occurred while deleting the user.");
          }
        };

        setColumns(dynamicColumns);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log(data, "data");

  return (
    <div className="App-header" style={{ width: "100%" }}>
      <h3 style={{ textAlign: "left" }}>Ajouter Collaborateur</h3>
      <Flex
        justify="space-between"
        style={{ width: "70%", marginBottom: "2rem" }}
      >
        <Form
          form={form}
          style={{ display: "flex", gap: "15px", width: "50%" }}
        >
          <Form.Item name="matricule">
            <Input
              placeholder="Matricule"
              type="text"
              style={{ width: "220px" }}
            />
          </Form.Item>
          <Form.Item name="nom">
            <Input
              placeholder="Nom et Prènom"
              type="text"
              style={{ width: "220px" }}
            />
          </Form.Item>
          <Button type="primary" onClick={handleAddUser}>
            Ajouter
          </Button>
        </Form>
        <Form.Item>
          <Input
            placeholder="Recherche"
            style={{ width: "200px" }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Form.Item>
      </Flex>
      <Table
        style={{ width: "80%" }}
        columns={columns}
        dataSource={filteredData}
        loading={loading}
        pagination={{ pageSize: 5 }}
      />
      <ToastContainer />
    </div>
  );
};

export default AllUsers;
