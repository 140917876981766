import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Input, Modal, TreeSelect } from "antd";
import mainLogo from "../mainLogo.png";
import NameLogo from "../NAME.png";
import URL from "../config";
import axios from "axios";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import "animate.css";
const ClientPage = () => {
  const [form] = Form.useForm();
  const [treeData, setTreeData] = useState([]);
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);
  const [selectedChoix, setSelectedChoix] = useState([]);
  const [selectedMontant, setSelectedMontant] = useState(0);
  const [selectedMontantRemise, setSelectedMontantRemise] = useState(0);
  const { matricule, nom } = useParams();

  const handleFormSubmit = async () => {
    try {
      const date = moment().format("YYYY/MM/DD");
      const consoData = {
        matricule: matricule,
        nom: nom,
        choix: selectedChoix,
        montant: selectedMontantRemise,
        date: date,
      };
      await axios.post(`${URL}/Conso/addNewConso`, consoData);
      toast.success("Votre achat a été bien effectué", {
        position: toast.POSITION.TOP_CENTER,
      });
      form.resetFields();
      setSelectedMontant(0);
      setSelectedMontantRemise(0);
    } catch (error) {
      toast.error("Erreur qui se produite", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onChange = (selectedValues) => {
    const selectedItems = treeData
      .flatMap((category) => category.children || [])
      .filter((item) => selectedValues.includes(item.titleSansRemise));

    const menuChoices = selectedItems
      .map((item) => item.titleSansRemise)
      .join(" / ");

    const totalMontant = selectedItems
      .map((item) => item.montant)
      .reduce((a, b) => a + b, 0);

    const totalMontantRemise = selectedItems
      .map((item) => item.remise)
      .reduce((a, b) => a + b, 0);

    setSelectedChoix(menuChoices);
    setSelectedMontant(totalMontant);
    setSelectedMontantRemise(totalMontantRemise);
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  useEffect(() => {
    axios
      .get(`${URL}/Menu/getMenuALL`)
      .then((response) => {
        const menuData = response.data;
        const categories = [...new Set(menuData.map((item) => item.category))];
        const formattedTreeData = categories.map((category) => {
          const categoryItems = menuData
            .filter((item) => item.category === category)
            .map((item) => ({
              key: item.choix,
              value: item.choix,
              title: `${item.choix}  ${item.montant.toFixed(2)} DT`,
              titleSansRemise: item.choix,
              remise: item.montantRemise,
              montant: item.montant,
            }));
          return {
            key: category,
            value: category,
            title: category,
            children: categoryItems,
          };
        });
        setTreeData(formattedTreeData);
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
      });
  }, []);

  //Modal Logic
  const [open, setOpen] = useState(true);
  const [passwordcheck, setPasswordCheck] = useState(null);
  const [passwordState, setPasswordState] = useState(true);
  const handlePassword = async () => {
    try {
      axios
        .post(`${URL}/CheckPass`, { passwordcheck: passwordcheck })
        .then((response) => {
          setPasswordState(response.data.success);
          if (response.data.success === true) {
            setOpen(false);
          }
        });
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleInputFocus = () => {
    setPasswordState(true);
  };

  return (
    <>
      <Modal
        title="Entrer votre mot de passe"
        open={open}
        footer={<Button onClick={handlePassword}>Envoyer</Button>}
      >
        <Input
          type="password"
          placeholder="Mot de passe"
          onChange={(e) => setPasswordCheck(e.target.value)}
          onFocus={handleInputFocus}
        />
        {
          <p style={{ color: "red" }}>
            {passwordState === false ? (
              <span>Votre mot de passe est incorrecte</span>
            ) : null}
          </p>
        }
      </Modal>
      <div className="App-header">
        <ToastContainer />
        <img src={mainLogo} className="App-logo" alt="logo" />
        <img
          src={NameLogo}
          alt="name logo"
          className="Name-logo animate__animated animate__flip"
        />
        <Form
          layout="horizontal"
          form={form}
          style={{ maxWidth: 800, width: "100%" }}
          className="form"
        >
          <Form.Item
            name="commande"
            label="Menu"
            rules={[
              { required: true, message: "veuillez choisir votre commande" },
            ]}
          >
            <TreeSelect
              showSearch
              style={{
                width: "100%",
              }}
              dropdownStyle={{
                maxHeight: 600,
                overflow: "auto",
              }}
              placeholder="Choisissez Votre Commande"
              allowClear
              multiple
              treeLine={true}
              treeExpandAction="click"
              treeDefaultExpandAll={false}
              onChange={(e) => onChange(e)}
              treeData={treeData}
            />
          </Form.Item>
          <div className="priceContainer animate__animated animate__backInLeft">
            <p className="montantText ">
              Montant total: <span className="total">{selectedMontant}DT</span>
            </p>
            <p className="montantText">
              Montant aprés réduction:{" "}
              <span className="total">
                {selectedMontantRemise.toFixed(2)}DT
              </span>
            </p>
          </div>
          <Form.Item
            style={{ marginTop: "4rem" }}
            className="animate__animated animate__bounceInUp"
          >
            <Button
              type="primary"
              style={{ width: "100%", color: "white" }}
              onClick={handleFormSubmit}
              disabled={!submittable}
            >
              Envoyer
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default ClientPage;
