import { Button, DatePicker, Flex, Form, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import URL from "../../config";

import axios from "axios";
import ExportTotalMount from "../ExportButtons/ExportTotalMount";

const { RangePicker } = DatePicker;

const AllUsersTable = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [totalMoney, setTotalMoney] = useState();
  const [totalMoneyFetched, setTotalMoneyFetched] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleReset = () => {
    form.resetFields();
    setSearchTerm("");
    setSelectedDateRange([]);
    setFetchedData([]);
  };

  const filteredFetchedData = fetchedData.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const onFinish = async () => {
    try {
      const requestData = {};

      if (selectedDateRange && selectedDateRange.length === 2) {
        const startDate = selectedDateRange[0].format("YYYY-MM-DD");
        const endDate = selectedDateRange[1].format("YYYY-MM-DD");

        requestData.startDate = startDate;
        requestData.endDate = endDate;

        const response = await axios.post(
          `${URL}/Rapport/TotalMontantByMatriculeByDate`,
          requestData
        );
        // Update fetchedData and columns based on the keys in fetchedData
        setFetchedData(response.data.results);
        setTotalMoneyFetched(response.data.totalMontant);
        const dynamicColumns = Object.keys(response.data.results[0]).map(
          (key) => ({
            title: key.charAt(0).toUpperCase() + key.slice(1),
            dataIndex: key,
            key: key,
          })
        );
      } else {
        console.error("Matricule is required");
      }
    } catch (error) {
      console.error("Error in onFinish:", error);
    }
  };

  const dataSource =
    fetchedData.length > 0 ? filteredFetchedData : filteredData;

  const displayTotalMontant =
    filteredFetchedData.length > 0 ? totalMoneyFetched : totalMoney;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${URL}/Rapport/TotalMontantByMatricule`
        );
        setData(response.data.results);
        setTotalMoney(response.data.totalMontant);

        const dynamicColumns = Object.keys(response.data.results[0]).map(
          (key) => ({
            title: key.charAt(0).toUpperCase() + key.slice(1),
            dataIndex: key,
            key: key,
          })
        );

        setColumns(dynamicColumns);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Flex
        justify="space-between"
        style={{ width: "100%", marginBottom: "2rem" }}
      >
        <div>
          <Form
            form={form}
            style={{ display: "flex", gap: "15px" }}
            onFinish={onFinish}
          >
            <RangePicker
              value={selectedDateRange}
              onChange={(value) => setSelectedDateRange(value)}
              style={{ width: "220px", height: "32px" }}
            />
            <Button type="primary" htmlType="submit">
              Recherche
            </Button>
            <Button type="primary" danger onClick={handleReset}>
              Reset
            </Button>
            <ExportTotalMount dates={selectedDateRange} />
          </Form>
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          {displayTotalMontant && (
            <span style={{ fontSize: "25px" }}>
              Montant Total:
              <strong style={{ color: "#b1987e" }}>
                {" "}
                {displayTotalMontant.toFixed(2)}DT
              </strong>
            </span>
          )}
        </div>
        <Input
          placeholder="Recherche"
          style={{ width: "200px", height: "32px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Flex>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default AllUsersTable;
