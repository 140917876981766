import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ClientPage from "./Components/ClientPage";
import TablePage from "./Components/AdminPages/TablePage";
import AllUsers from "./Components/AllUsers";
import StaticMenu from "./Components/pages/StaticMenu";
import { ConfigProvider } from "antd";
import MenuCrud from "./Components/WaiterPages/MenuCrud";
import SpecialClientPage from "./Components/SpecialClientPage";

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              colorText: "white",
            },
            Pagination: {
              colorText: "white",
            },
          },
        }}
      >
        <Router>
          <Routes>
            <Route path="/:matricule/:nom" exact element={<ClientPage />} />
            <Route path="/:nom" exact element={<SpecialClientPage />} />
            <Route path="/admin" element={<TablePage />} />
            <Route path="/users" element={<AllUsers />} />
            <Route path="/menu" element={<StaticMenu />} />
            <Route path="/modifMenu" element={<MenuCrud />} />
          </Routes>
        </Router>
      </ConfigProvider>
    </div>
  );
}

export default App;
