export const category = [
  { value: "BOISSONS CHAUDES", label: "BOISSONS CHAUDES" },
  { value: "BOISSONS FRAÎCHES", label: "BOISSONS FRAÎCHES" },
  { value: "CRÊPES ET GAUFRES", label: "CRÊPES ET GAUFRES" },
  { value: "CRÊPES SALÉES", label: "CRÊPES SALÉES" },
  { value: "SANDWICHS", label: "SANDWICHS" },
  { value: "ENTRÉES CHAUDES", label: "ENTRÉES CHAUDES" },
  { value: "SALADES", label: "SALADES" },
  { value: "SAVEURS FERMIÈRES", label: "SAVEURS FERMIÈRES" },
  { value: "PIZZA", label: "PIZZA" },
  { value: "PÂTES", label: "PÂTES" },
  { value: "POISSONS", label: "POISSONS" },
];
