import { Button, Flex, Form, Input, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import URL from "../../config";
import axios from "axios";
import AddNewArticle from "./AddNewArticle";

const MenuCrud = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  //filter
  const filteredData =
    data &&
    data.filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  //Crud operation
  const handleModify = (record) => {
    // Logic for modify operation
    console.log("Modify operation for record:", record);
  };

  const handleDelete = (record) => {
    // Logic for delete operation
    console.log("Delete operation for record:", record);
  };

  //getting Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}/Menu/getMenuALL`);
        setData(response.data);

        const dynamicColumns = Object.keys(response.data[0]).map((key) => ({
          title: key.charAt(0).toUpperCase() + key.slice(1),
          dataIndex: key,
          key: key,
        }));

        dynamicColumns.push({
          title: "Actions",
          key: "actions",
          render: (text, record) => (
            <Space size="middle">
              <Button type="primary" onClick={() => handleModify(record)}>
                Modifier
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => handleDelete(record)}
              >
                Supprimer
              </Button>
            </Space>
          ),
        });

        setColumns(dynamicColumns);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App-header">
      <Flex
        justify="space-between"
        style={{ width: "100%", marginBottom: "2rem" }}
      >
        <AddNewArticle />
        <Input
          placeholder="Recherche"
          style={{ width: "200px", height: "32px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Flex>
      <Table
        columns={columns}
        dataSource={filteredData}
        loading={loading}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default MenuCrud;
