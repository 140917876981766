import React from "react";

import AllUsersTable from "./AllUsersTable";
import UsersTable from "./UsersTable";

import { Tabs } from "antd";
import SpecialUsers from "./SpecialUsers";

const TablePage = () => {
  return (
    <div className="App-header">
      <Tabs
        style={{ width: "100%" }}
        tabPosition="top"
        items={[
          {
            label: "Historique de tous les achats",
            key: "1",
            children: <UsersTable />,
          },
          {
            label:
              "Historique des achats des collaborateurs avec les montants total",
            key: "2",
            children: <AllUsersTable />,
          },
          {
            label: "Historique des achats pour Ramy et Imen",
            key: "3",
            children: <SpecialUsers />,
          },
        ]}
      />
    </div>
  );
};

export default TablePage;
