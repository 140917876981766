import { Button } from "antd";
import React from "react";
import axios from "axios";

import URL from "../../config";

const ExportTotalMount = ({ dates }) => {
  const handleExport = async () => {
    try {
      const requestData = {};

      if (dates && dates.length === 2) {
        const startDate = dates[0].format("YYYY-MM-DD");
        const endDate = dates[1].format("YYYY-MM-DD");

        requestData.startDate = startDate;
        requestData.endDate = endDate;
      }

      const response = await axios.post(
        `${URL}/Rapport/ExportTotalMontantByMatricule `,
        requestData,
        { responseType: "arraybuffer" } // Use "arraybuffer" to handle binary data
      );

      // Handle the array buffer data here
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "exported_data.xlsx"; // Use ".xlsx" extension for Excel files
      document.body.appendChild(a);

      // Trigger a click on the link to initiate the download
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error in ExportButton:", error);
    }
  };

  return (
    <Button type="dashed" onClick={handleExport}>
      Exporter
    </Button>
  );
};

export default ExportTotalMount;
