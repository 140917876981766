import { Button, Form, Input, Modal, Select, message, Upload } from "antd";
import React, { useState } from "react";
import URL from "../../config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { category } from "../../elements";
import { UploadOutlined } from "@ant-design/icons";

const AddNewArticle = () => {
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    try {
      await form.submit(); // This will trigger form submission
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAddArticle = async (values) => {
    try {
      const formData = new FormData();
      formData.append("category", values.category);
      formData.append("choix", values.choix);
      formData.append("montant", values.montant);
      formData.append("imageArticle", values.imageArticle);

      console.log(formData, "formData");

      await axios.post(`${URL}/Menu/addNewChoix`, formData);

      toast.success("Choix ajouté avec succès", {
        position: toast.POSITION.TOP_CENTER,
      });
      form.resetFields();
    } catch (error) {
      console.error("Error adding choice:", error);
      message.error("Choix déjà utilisé !");
    }
  };

  const props = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  return (
    <>
      <Form form={form} onFinish={handleAddArticle}>
        <Button type="primary" onClick={showModal}>
          Ajouter un nouveau article
        </Button>
        <Modal
          title="Basic Modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={() => setIsModalOpen(false)}
          cancelText="Annuler"
          okText="Envoyer"
        >
          <Form.Item
            name="category"
            label="Catégorie"
            rules={[{ required: true, message: "Veuillez entrer le choix" }]}
          >
            <Select style={{ width: 200 }} options={category} showSearch />
          </Form.Item>

          <Form.Item
            name="choix"
            label="Choix"
            rules={[{ required: true, message: "Veuillez entrer le choix" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="montant"
            label="Montant"
            rules={[{ required: true, message: "Veuillez entrer le montant" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="imageArticle" label="Image">
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
};

export default AddNewArticle;
